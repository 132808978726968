import CIcon from '@coreui/icons-react';
import { cilPeople, cilCloudDownload, cilSettings } from '@coreui/icons';
import { ReactComponent as DashboardIcon } from '@/resources/assets/icons/dashboard.svg';
import { ReactComponent as DevicesIcon } from '@/resources/assets/icons/devices.svg';
import { ReactComponent as LocationIcon } from '@/resources/assets/icons/location.svg';
import { ReactComponent as HelpIcon } from '@/resources/assets/icons/help.svg';
import { LINKS } from '@/shared/constants';

const getCoreUIIcon = (icon) => (className) => <CIcon className={className} icon={icon} size="custom" />;

const nav = [
  {
    name: 'dashboard',
    to: '/dashboard',
    icon: (className) => <DashboardIcon className={className} />,
  },
  {
    name: 'devices',
    to: '/devices',
    icon: (className) => <DevicesIcon className={className} />,
  },
  {
    name: 'users',
    to: '/users',
    icon: getCoreUIIcon(cilPeople),
  },
  {
    name: 'locations',
    to: '/map',
    icon: (className) => <LocationIcon className={className} />,
  },
  {
    name: 'exports',
    to: '/exports',
    icon: getCoreUIIcon(cilCloudDownload),
  },
  {
    name: 'organization_settings',
    to: '/settings',
    icon: getCoreUIIcon(cilSettings),
  },
  {
    name: 'help',
    to: LINKS.support,
    icon: (className) => <HelpIcon className={className} />,
    analyticName: 'menu_support',
    external: true,
  },
];

export default nav;
