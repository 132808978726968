import { Navigate } from "react-router-dom";

import { usePermissions } from '@/processes/use-permissions';

const ProtectedRoute = ({ children, restrictedFor = [] }) => {
  const { isViewer, isAnyLocAdmin, isCurrentOrgAdmin } = usePermissions();

  let isRestrictedRoute = false;

  restrictedFor.forEach((userRole) => {
    switch (userRole) {
      case 'ORG_ADMIN':
        isRestrictedRoute = isCurrentOrgAdmin;
        break;
      case 'LOC_ADMIN':
        isRestrictedRoute = isAnyLocAdmin && !isCurrentOrgAdmin;
        break;
      case 'VIEWER':
        isRestrictedRoute = isViewer && !isAnyLocAdmin && !isCurrentOrgAdmin;
        break;
      default:
        break;
    }
  });

  if (isRestrictedRoute) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

export default ProtectedRoute;
