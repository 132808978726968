import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import { wrapCreateBrowserRouter } from "@sentry/react";

import { reactLazyLoad } from '@/app/helpers/react-lazy-load';
import LayoutMain from '@/app/layouts/main';
import LayoutAuth from '@/app/layouts/auth';
import LayoutEmpty from '@/app/layouts/empty';
import { ErrorRoot, Error404, Error500 } from "@/pages/error";
import { LINKS } from '@/shared/constants';

import AuthorizedRoute from './AuthorizedRoute';
import AuthorizationLogicRoute from './AuthorizationLogicRoute';
import RedirectOutside from './RedirectOutside';
import NeedOrganizationRoute from "./NeedOrganizationRoute";
import NeedLocationRoute from './NeedLocationRoute';
import NeedDeviceRoute from './NeedDeviceRoute';
import ProtectedRoute from './ProtectedRoute';

const Dashboard = reactLazyLoad(() => import('@/pages/dashboard/Dashboard'), 'Dashboard');
const Devices = reactLazyLoad(() => import('@/pages/devices/Devices/Devices'));
const AddDevice = reactLazyLoad(() => import('@/pages/devices/AddDevice/AddDevice'));
const EditDevice = reactLazyLoad(() => import('@/pages/devices/EditDevice/EditDevice'));
const Users = reactLazyLoad(() => import('@/pages/users/Users'), 'Users');
const InviteEditUser = reactLazyLoad(() => import('@/pages/users/InviteEditUser'));
const Locations = reactLazyLoad(() => import('@/pages/locations/Locations'));
const AddLocation = reactLazyLoad(() => import('@/pages/locations/AddLocation'));
const EditLocation = reactLazyLoad(() => import('@/pages/locations/EditLocation'));
const AddFloor = reactLazyLoad(() => import('@/pages/locations/AddFloor'));
const EditFloor = reactLazyLoad(() => import('@/pages/locations/EditFloor'));
const Exports = reactLazyLoad(() => import('@/pages/exports/Exports'));
const Notifications = reactLazyLoad(() => import('@/pages/notifications/Notifications'));
const Settings = reactLazyLoad(() => import('@/pages/organization/Settings'));
const Plan = reactLazyLoad(() => import('@/pages/organization/Plan/Plan'));
const PlanSelection = reactLazyLoad(() => import('@/pages/organization/Plan/PlanSelection'));
const Profile = reactLazyLoad(() => import('@/pages/profile/Profile'));
const ChangePassword = reactLazyLoad(() => import('@/pages/profile/ChangePassword'));
const CreateWorkspace = reactLazyLoad(() => import('@/pages/organization/CreateWorkspace'));
const HelpDesk = reactLazyLoad(() => import('@/pages/help-desk/HelpDesk'));
const HelpDeskOrganization = reactLazyLoad(() => import('@/pages/help-desk/HelpDeskOrganization'));
const HelpDeskDevice = reactLazyLoad(() => import('@/pages/help-desk/HelpDeskDevice'));
const HelpDeskPromocode = reactLazyLoad(() => import('@/pages/help-desk/HelpDeskPromocode'));
const FileDownload = reactLazyLoad(() => import('@/pages/fileDownload/FileDownload'), 'FileDownload');
// TODO: Implement it using react-router errors logic
const AccessError = reactLazyLoad(() => import('@/pages/accessError/AccessError'));
// Auth routes
const SignUp = reactLazyLoad(() => import('@/pages/authorization/register/SignUp'), 'SignUp');
const Login = reactLazyLoad(() => import('@/pages/authorization/login/Login'), 'Login');
const TwoFactorAuth = reactLazyLoad(() => import('@/pages/authorization/twoFactorAuth/TwoFactorAuth'), 'TwoFactorAuth');
const ConfirmEmail = reactLazyLoad(() => import('@/pages/authorization/register/ConfirmEmail'), 'ConfirmEmail');
const ResetPassword = reactLazyLoad(() => import('@/pages/authorization/password/ResetPassword'), 'ResetPassword');
const ResetPasswordConfirm = reactLazyLoad(() => import('@/pages/authorization/password/ResetPasswordConfirm'), 'ResetPasswordConfirm');
const ActivateUser = reactLazyLoad(() => import('@/pages/authorization/register/ActivateUser'), 'ActivateUser');
const ActivateInvitation = reactLazyLoad(() => import('@/pages/authorization/register/ActivateInvitation'), 'ActivateInvitation');
const ConfirmTransfer = reactLazyLoad(() => import('@/pages/authorization/transferOwnership/ConfirmTransfer'), 'ConfirmTransfer');
// Public routes
const TVLink = reactLazyLoad(() => import('@/pages/TVapp/TVLink'), 'TVLink');

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(
  createBrowserRouter,
);

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: (
      <AuthorizedRoute>
        <LayoutMain />
      </AuthorizedRoute>
    ),
    errorElement: <ErrorRoot />,
    handle: {
      crumb: false,
    },
    children: [
      {
        index: true,
        element: <Navigate to="/dashboard" replace={true} />,
      },
      {
        path: 'dashboard',
        handle: {
          name: 'dashboard',
        },
        element: (
          <NeedOrganizationRoute>
            <NeedLocationRoute>
              <NeedDeviceRoute>
                <Dashboard />
              </NeedDeviceRoute>
            </NeedLocationRoute>
          </NeedOrganizationRoute>
        ),
      },
      {
        path: 'devices',
        element: (
          <NeedOrganizationRoute>
            <NeedLocationRoute>
              <NeedDeviceRoute>
                <Outlet />
              </NeedDeviceRoute>
            </NeedLocationRoute>
          </NeedOrganizationRoute>
        ),
        children: [
          {
            index: true,
            handle: {
              // If true, we will get two routes in breadcrumbs: /devices and /devices/
              crumb: false,
            },
            element: <Devices />,
          },
          {
            path: 'add',
            handle: {
              name: 'add_device',
            },
            element: (
              <ProtectedRoute restrictedFor={['VIEWER']}>
                <AddDevice />
              </ProtectedRoute>
            ),
          },
          {
            path: 'edit/:id',
            handle: {
              name: 'edit_device',
            },
            element: (
              <ProtectedRoute restrictedFor={['VIEWER']}>
                <EditDevice />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'users',
        element: (
          <ProtectedRoute restrictedFor={['VIEWER']}>
            <Outlet />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            handle: {
              // If true, we will get two routes in breadcrumbs: /users and /users/
              crumb: false,
            },
            element: <Users />,
          },
          {
            path: 'invite',
            handle: {
              name: 'invite_user',
            },
            element: <InviteEditUser />,
          },
          {
            path: 'edit/:id',
            handle: {
              name: 'edit_user',
            },
            element: <InviteEditUser />,
          },
        ],
      },
      {
        path: 'map',
        element: (
          <ProtectedRoute restrictedFor={['VIEWER']}>
            <NeedLocationRoute>
              <Outlet />
            </NeedLocationRoute>
          </ProtectedRoute>
        ),
        handle: {
          name: 'locations',
        },
        children: [
          {
            index: true,
            handle: {
              // If true, we will get two routes in breadcrumbs: /locations and /locations/
              crumb: false,
            },
            element: <Locations />,
          },
          {
            path: 'add',
            handle: {
              name: 'add_location',
            },
            element: (
              <ProtectedRoute restrictedFor={['LOC_ADMIN']}>
                <AddLocation />
              </ProtectedRoute>
            ),
          },
          {
            path: 'edit/:id',
            handle: {
              name: 'edit_location',
            },
            element: (
              <ProtectedRoute restrictedFor={['LOC_ADMIN']}>
                <EditLocation />
              </ProtectedRoute>
            ),
          },
          {
            path: 'add_floor',
            handle: {
              name: 'add_floor',
            },
            element: <AddFloor />,
          },
          {
            path: 'edit_floor/:id',
            handle: {
              name: 'edit_floor',
            },
            element: <EditFloor />,
          },
        ],
      },
      {
        path: 'exports',
        element: <Exports />,
      },
      {
        path: 'notifications',
        element: <Notifications />,
      },
      {
        path: 'settings',
        element: (
          <ProtectedRoute restrictedFor={['VIEWER', 'LOC_ADMIN']}>
            <Outlet />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            handle: {
              // If true, we will get two routes in breadcrumbs: /settings and /Settings/
              crumb: false,
            },
            element: <Settings />,
          },
          {
            path: 'plan',
            handle: {
              name: 'cloud_plan_overview',
            },
            element: <Plan />,
          },
        ],
      },
      {
        path: 'plan_selection',
        handle: {
          name: 'cloud_tariff_plans',
        },
        element: <PlanSelection />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'change_password',
        element: <ChangePassword />,
      },
      {
        path: 'create_workspace',
        element: <CreateWorkspace />,
      },
      {
        path: 'help_desk_admin',
        children: [
          {
            index: true,
            handle: {
              // If true, we will get two routes in breadcrumbs: /help_desk_admin and /help_desk_admin/
              crumb: false,
            },
            element: <HelpDesk />,
          },
          {
            path: 'organization/:id',
            handle: {
              name: 'help_desk_organization',
            },
            element: <HelpDeskOrganization />,
          },
          {
            path: 'device/:id',
            handle: {
              name: 'help_desk_device',
            },
            element: <HelpDeskDevice />,
          },
          {
            path: 'promocode/:id',
            handle: {
              name: 'help_desk_activation_key',
            },
            element: <HelpDeskPromocode />,
          },
        ],
      },
      // TODO: Try to process such cases using react router exceptions and errorElement
      {
        path: 'access_error',
        element: <AccessError />,
      },
    ],
  },
  {
    path: '/auth',
    element: (
      <AuthorizationLogicRoute>
        <LayoutAuth />
      </AuthorizationLogicRoute>
    ),
    errorElement: <ErrorRoot />,
    children: [
      {
        path: 'sign_up',
        element: <SignUp />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'two_factor_auth',
        element: <TwoFactorAuth />,
      },
      {
        path: 'confirm_email',
        element: <ConfirmEmail />,
      },
      {
        path: 'reset_password',
        element: <ResetPassword />,
      },
    ],
  },
  // Maintaning legacy routes. Maybe we can remove it later.
  {
    path: '/sign_up',
    element: <Navigate to="/auth/sign_up" replace={true} />,
  },
  {
    path: '/login',
    element: <Navigate to="/auth/login" replace={true} />,
  },
  {
    path: '/two_factor_auth',
    element: <Navigate to="/auth/two_factor_auth" replace={true} />,
  },
  {
    path: '/confirm_email',
    element: <Navigate to="/auth/confirm_email" replace={true} />,
  },
  {
    path: '/reset_password',
    element: <Navigate to="/auth/reset_password" replace={true} />,
  },
  // Maybe later we can implement redirect with saving matching params and move following 4 routes to auth/
  {
    path: '/password/reset/confirm/:uid/:token',
    element: <LayoutAuth />,
    children: [
      {
        index: true,
        element: <ResetPasswordConfirm />,
      },
    ],
  },
  {
    path: '/activate/:type/:uid/:token',
    element: <LayoutAuth />,
    children: [
      {
        index: true,
        element: <ActivateUser />,
      },
    ],
  },
  {
    path: '/proceed_invitation/:uid/:token',
    element: <LayoutAuth />,
    children: [
      {
        index: true,
        element: <ActivateInvitation />,
      },
    ],
  },
  {
    path: '/confirm_transfer/:uid/:org_uid/:token',
    element: <LayoutAuth />,
    children: [
      {
        index: true,
        element: <ConfirmTransfer />,
      },
    ],
  },
  // Public routes
  {
    path: '/p',
    element: <LayoutEmpty />,
    errorElement: <ErrorRoot />,
    children: [
      {
        path: ':public_uuid',
        element: <TVLink />,
      },
    ],
  },
  // External redirects
  {
    path: '/warranty',
    element: <RedirectOutside to={LINKS.warrantyLink} />,
  },
  {
    path: '/plan_upgrade',
    element: <RedirectOutside to={LINKS.planUpgrade} />,
  },
  {
    path: '/faq',
    element: <RedirectOutside to={LINKS.faqLink} />,
  },
  {
    path: '/terms',
    element: <RedirectOutside to={LINKS.termsLink} />,
  },
  {
    path: '/regulatory',
    element: <RedirectOutside to={LINKS.regulatoryLink} />,
  },
  {
    path: '/displayapp',
    element: <RedirectOutside to={LINKS.displayApp} />,
  },
  {
    path: '/privacy',
    element: <RedirectOutside to={LINKS.privacyPolicy} />,
  },
  {
    path: '/privacy',
    element: <RedirectOutside to={LINKS.privacyPolicy} />,
  },
  {
    path: '/download',
    element: <LayoutEmpty />,
    errorElement: <ErrorRoot />,
    children: [
      {
        index: true,
        element: <FileDownload />,
      },
    ],
  },
  // Errors
  {
    path: '/error',
    element: <LayoutEmpty />,
    errorElement: <ErrorRoot />,
    children: [
      {
        index: true,
        element: <ErrorRoot />,
      },
      {
        path: '404',
        element: <Error404 />,
      },
      {
        path: '500',
        element: <Error500 />,
      },
    ],
  },
]);

export default router;
